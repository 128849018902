var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showPublishButton
    ? _c(
        "div",
        { staticClass: "portfolio-resource-publish" },
        [
          _c("p", { staticClass: "portfolio-resource-publish-text" }, [
            _vm._v(
              " Please review this generated content before publishing it. "
            )
          ]),
          _c("Button", {
            attrs: {
              text: "Approve & publish",
              "icon-left": true,
              icon: "check-circle"
            },
            on: { click: _vm.handlePublish }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }