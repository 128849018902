<template>
  <div v-if="showPublishButton" class="portfolio-resource-publish">
    <p class="portfolio-resource-publish-text">
      Please review this generated content before publishing it.
    </p>
    <Button
      :text="`Approve & publish`"
      :icon-left="true"
      icon="check-circle"
      @click="handlePublish"
    />
  </div>
</template>

<script>
import { editKeyContentTemplateResource } from '@/services/keyContentService'
import Button from '@c/library/Button.vue'
import { mapState } from 'vuex'

export default {
  name: 'PortfolioResourceModalPublish',
  components: {
    Button
  },
  props: {
    resource: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    forceHide: false
  }),
  computed: {
    ...mapState({
      resourceModalRootProps: (state) =>
        state.resourceModals.resourceModal?.rootProps || {}
    }),
    showPublishButton() {
      return (
        (!this.forceHide && this.resourceModalRootProps.needsReview) || false
      )
    }
  },
  methods: {
    async handlePublish() {
      try {
        const res = await editKeyContentTemplateResource({
          workspace_id: this.$route.params.workspace_id,
          template_id: this.resourceModalRootProps.templateId,
          file_id: this.resource.integrationfile.uuid,
          status: 'published',
          act_as: this.$route.query.act_as
        })
        let bc = new BroadcastChannel('uman_template_resource_publish')
        bc.postMessage({ event: 'published', result: res })
        this.$toast.success(
          'Content published',
          'Your content has been published successfully.'
        )
        this.forceHide = true
      } catch (e) {
        this.$console.debug('Publishing of content failed', e)
        this.$toast.error(e, 'publishing your content')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.portfolio-resource-publish {
  margin-bottom: 1.5rem;
  padding: 1rem 1.5rem;
  border-radius: 4px;
  border: 1px solid $primary;
  background: #ebf1ff;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  &-text {
    font-weight: 600;
    font-size: 1.15rem;
    color: $primary;
  }
}
</style>
